<!-- 页面程序 -->
<template>  
    <iframe :src="htmlStr" class="content"></iframe>
</template>



<!-- JS交互 -->
<script>
export default {
  components: {},
  data() {
    return {
        htmlStr: 'https://www.yingyuchat.com/chatIndex?kefu_id=a15923943923&ent_id=2087&lang=cn&' + this.$route.query.params,
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<!-- class 展示属性 -->
<style lang="less" scoped>
.content {
    background: white;
    width: 100vw;
    height: 100vh;
}

</style>